<template>
    <div>
        <h1 v-if="level == 1" class="h1">
            <slot></slot>
        </h1>
        <h2 v-if="level == 2" class="h2">
            <slot></slot>
        </h2>
        <span v-if="level === null" :class="styling">
            <slot></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: "BaseHeadline",
        props: {
            level: {
                type: Number,
                default: null,
            },
            styling: {
                type: String,
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>