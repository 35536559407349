<template>
    <div>
        <CopyBox align="full" headline="imprint.">
            <p>
                Commonway Interaction<br />
                Tobias Renn
            </p>
            <p>
                Nagelsweg 22f<br />
                20097 Hamburg, Germany
            </p>
            <p>
                <a href="mailto:moin@commonway.de">moin@commonway.de</a>
            </p>
            <p>
                USt ID DE256 516 516
            </p>
        </CopyBox>
    </div>
</template>

<script>
    import CopyBox from '@/components/CopyBox.vue';

    export default {
        name: "ImprintPage",
        components: { 
            CopyBox
        }
    }
</script>

<style lang="scss" scoped>
</style>