<template>
    <div class="home">
        <BaseHeadline level="1">moin.</BaseHeadline>
        <Divider />
        <BaseHeadline level="2">
            powerful communication.<br />
            for you. and your customers.
        </BaseHeadline>
        <BaseButton inverted text="Get in touch!" link="imprint" />
    </div>
</template>

<script>
    import Divider from '@/components/Divider.vue';

    export default {
        name: "HomePage",
        components: { 
            Divider
        }
    }
</script>

<style lang="scss" scoped>
.home {
    text-align: center;
}
</style>