<template>
    <header>
        <router-link :to="{ name: 'home' }">
            <img src="./../assets/logo-commonway.svg" alt="Commonway Interaction" />
        </router-link>
    </header>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Header"
    }
</script>

<style lang="scss" scoped>
header {
    margin-bottom: var(--spacer-l);
    text-align: center;
}
</style>