<template>
    <span>
        <router-link :to="{ name: link }">
            <button :class="isInverted" v-bind="$attrs">
                {{ text }}
            </button>
        </router-link>
    </span>
  </template>
  
  <script>
  export default {
    name: 'BaseButton',
    props: {
      text: {
        type: String
      },
      link: String,
      inverted: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isInverted: function() {
        if (this.inverted === true) {
          return 'inverted'
        }
        return false
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  button {
    margin: var(--spacer-s) 0;
    padding: 13px 25px;
    background-color: var(--clr-white);
    border: var(--bw-bold) solid var(--clr-primary);
    border-radius: 50px;
    outline: none;
    text-decoration: none;
    font-size: 18px;
    font-weight: var(--fw-800);
    display: inline-block;
    cursor: pointer;
  
    &.inverted {
      background-color: var(--clr-primary);
      border-color: var(--clr-primary-light);
      color: var(--clr-white);

      &:hover {
        color: var(--clr-black);
        border-color: var(--clr-white);
      }
    }
    &:hover {
      background-color: var(--clr-white);
      color: var(--clr-white);
    }
    &:disabled {
      color: var(--clr-white);
    }
  }
  </style>
  