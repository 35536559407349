<template>
    <div class="divider">
    </div>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Divider"
    }
</script>

<style lang="scss" scoped>
.divider {
    width: 85%;
    margin-left: calc(var(--spacer-m) * -1);
    border-bottom: 4px solid var(--clr-white);
}
</style>