import { render, staticRenderFns } from "./ImprintPage.vue?vue&type=template&id=27a5a650&scoped=true&"
import script from "./ImprintPage.vue?vue&type=script&lang=js&"
export * from "./ImprintPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a5a650",
  null
  
)

export default component.exports