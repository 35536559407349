<template>
  <div id="app">
    <div class="wrapper">
      <Header />
      <main>
        <router-view />
      </main>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
@import url('./css/root.css');

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--ff);
  font-size: var(--fs-m);
  color: var(--clr-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: underline;
  color: black;
}

#app {
  .wrapper {
    display: grid;
    align-content: space-between;
    height: 100vh;
    padding: var(--spacer-m);
    border: var(--spacer-m) solid var(--clr-white);
    background-color: var(--clr-background);
  }
}
</style>
