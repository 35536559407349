<template>
    <div>
        <h1 class="h1">404.</h1>
    </div>
</template>

<script>
    export default {
        name: "HomePage"
    }
</script>

<style lang="scss" scoped>
.h1 {
    font-family: Nunito, Arial, Helvetica, sans-serif;
}
</style>