<template>
    <div :class="align">
      <BaseHeadline level="2" v-if="headline">
        {{ headline }}
      </BaseHeadline>
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CopyBox',
    props: {
      align: {
        type: String,
        default: 'left'
      },
      headline: {
        type: String
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .left,
  .right,
  .full {
    font-size: var(--fs-m);
    line-height: 1.5em;
    margin: var(--spacer-xl) 0;
  }
  .left {
    width: 50%;
  }
  .right {
    margin-left: 50%;
    width: 50%;
  }
  .full {
    width: 100%;
  }
  </style>
  