<template>
    <footer>
        <span class="love">Made with ❤️ in Hamburg, Germany.</span> 
        <span>
            <router-link :to="{ name: 'imprint'}">Imprint</router-link>
        </span>
    </footer>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Footer"
    }
</script>

<style lang="scss" scoped>
footer {
    .love {
        margin-right: var(--spacer-m);
    }

    margin-top: var(--spacer-l);
    font-size: var(--fs-s);
    text-align: center;
    color: var(--clr-white);

    a {
        color: var(--clr-white);
        text-decoration: none;
    }
}
</style>