import Vue from 'vue'
import router from './router'
import BaseButton from '@/components/BaseButton.vue'
import BaseHeadline from '@/components/BaseHeadline.vue'
import App from './App.vue'

Vue.config.productionTip = false

Vue.component('BaseButton', BaseButton)
Vue.component('BaseHeadline', BaseHeadline)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
