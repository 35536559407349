import Vue from 'vue'
import Router from 'vue-router'
import HomePage from './views/HomePage.vue'
import ImprintPage from '@/views/ImprintPage.vue'
import NotFound from '@/views/404Page.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage,
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: ImprintPage,
    },
    {
      path: '/:catchAll(.*)',
      component: NotFound,
      name: 'NotFound',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
